import { Portuguese } from "flatpickr/dist/l10n/pt.js"

window.jQuery = $;
window.$ = $;

document.addEventListener("turbolinks:load", function () {
  function setParamsFilterCalendar(key, value) {
    const urlParams = new URLSearchParams(window.location.search);

    urlParams.set(key, value);

    const newUrl = window.location.pathname + '?' + urlParams.toString();
    history.pushState(null, '', newUrl);
  }

  function setMaxDateFilterStart() {
    flatpickr("#filter-calendar-date-start", {
      locale: Portuguese,
      dateFormat: "d/m/Y",
      altInput: true,
      altFormat: "d/m/Y",
      maxDate: $('#filter-calendar-date-end').val()
    });
  }

  function setMinDateFilterEnd() {
    flatpickr("#filter-calendar-date-end", {
      locale: Portuguese,
      dateFormat: "d/m/Y",
      altInput: true,
      altFormat: "d/m/Y",
      minDate: $('#filter-calendar-date-start').val()
    });
  }

  $('#filter-calendar-partner-id').on('change', () => {
    setParamsFilterCalendar('partner_id', $('#filter-calendar-partner-id').val());

    updateLinkDownload();
  })
  $('#filter-calendar-date-start').on('change', () => {
    setParamsFilterCalendar('start_date', $('#filter-calendar-date-start').val());

    updateLinkDownload();

    setMinDateFilterEnd();
  })
  $('#filter-calendar-date-end').on('change', () => {
    setParamsFilterCalendar('end_date', $('#filter-calendar-date-end').val());

    updateLinkDownload();

    setMaxDateFilterStart();
  })

  setMaxDateFilterStart();
  setMinDateFilterEnd();

  $('#clear-calendar-filters').on('click', () => {
    const urlParams = new URLSearchParams(window.location.search);

    urlParams.delete('partner_id');
    urlParams.delete('start_date');
    urlParams.delete('end_date');

    window.location = window.location.pathname + '?' + urlParams.toString();
  })

  function updateLinkDownload() {
    const href = $('#download-calendar').attr('href');

    const url = new URL(href, window.location.origin);

    url.searchParams.set('partner_id', $('#filter-calendar-partner-id').val());
    url.searchParams.set('start_date', $('#filter-calendar-date-start').val());
    url.searchParams.set('end_date', $('#filter-calendar-date-end').val());

    $('#download-calendar').attr('href', url.toString());
  }

  function selectSteps(select_all_checkbox_id, item_checkbox_class, btn_create_resume_id) {
    const selectAllCheckbox = $(select_all_checkbox_id);
    const individualCheckboxes = $(item_checkbox_class);
    const btnCreateResume = $(btn_create_resume_id);

    function updateButtonState() {
      const hasSelected = individualCheckboxes.filter(':checked').length > 0;
      if (!hasSelected) {
        btnCreateResume.addClass('disabled').attr('aria-disabled', 'true');
      } else {
        btnCreateResume.removeClass('disabled').removeAttr('aria-disabled');
      }

      updateButtonHref();
    }

    selectAllCheckbox.on('change', function () {
      individualCheckboxes.prop('checked', selectAllCheckbox.prop('checked'));
      updateButtonState();
    });

    individualCheckboxes.on('change', function () {
      const allChecked = individualCheckboxes.length === individualCheckboxes.filter(':checked').length;
      selectAllCheckbox.prop('checked', allChecked);
      updateButtonState();
    });

    function updateButtonHref() {
      const selectedIds = individualCheckboxes.filter(':checked').map(function () {
        return $(this).val();
      }).get();

      let href = btnCreateResume.attr('href');
      const url = new URL(href, window.location.href);
      url.searchParams.set('ids', selectedIds);
      btnCreateResume.attr('href', url.toString());
    }

    updateButtonState();
  }

  function selectManualLabors() {
    selectSteps('#select_all_manual_labors', '.select_item_manual_labor', '#create_resume_manual_labors');
  }

  function selectMaterialPayments() {
    selectSteps('#select_all_material_payments', '.select_item_material_payment', '#create_resume_material_payments');
  }

  window.selectManualLabors = selectManualLabors;
  window.selectMaterialPayments = selectMaterialPayments;

  selectManualLabors();
  selectMaterialPayments();
});
