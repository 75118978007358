window.jQuery = $;
window.$ = $;

document.addEventListener("turbolinks:load", function () {
  let orderUrl = $('.orders-select-remote').data('order-url');
  let token = $('meta[name="csrf-token"]').attr('content');

  function bindingClearForm() {
    $('.clear').on('click', function () {
      let form = $(this).closest('form');

      clearForm(form);
    });
  }

  function clearForm(form) {
    form.find('input, textarea').each(function () {
      let field = $(this);

      if (field.attr('type') == 'hidden') {
        return
      } else if (field.attr('type') == 'checkbox') {
        field.prop('checked', false)
      } else {
        field.val('');
      }
    });

    if (form.find('.form-control-select2').length > 0) {
      form.find('.form-control-select2').select2("val", 0);
    }

    if (form.find('.form-uploader-container').length > 0) {
      $('.uppy-Dashboard-Item-action--remove').each(function () {
        this.click();
      });
    }

    $('.date-picker').val('');
  }

  function bindingValidateForm() {
    $('.validate').validate({ errorClass: 'text-danger' });
    $('.validate').on('submit', function () {
      let field_required = true;
      let form = $(this);

      let btn_submit = $('#submit-club-transaction');

      btn_submit.prop('disabled', true);

      form.find('.required').each(function () {
        if ($(this).val().length < 1) {
          field_required = false;
        }
      })

      if (!form.valid()) {
        btn_submit.prop('disabled', false);

        return false;
      }

      if (!field_required) {
        alert('Favor preencher os campos obrigatórios');
      }

      if (form.valid() && field_required) {
        return true
      } else {
        btn_submit.prop('disabled', false);

        return false
      }
    });
  }

  function formatOrders(order) {
    return `Nº OS: ${order.id}`;
  }

  $('.finished_order_wizard').on('click', function () {
    let orderUrl = $(this).data('wizard-url');
    let currentStep = $(this).data('wizard-step');

    $.ajax({
      type: "PATCH",
      url: orderUrl,
      data: {
        construction: {
          current_step: currentStep
        }
      }
    }).done(function () {
      document.location.reload(true);
    });
  })

  $('.orders-select-remote').select2({
    ajax: {
      url: orderUrl,
      dataType: 'json',
      delay: 500,
      data: function (params) {
        return {
          query: params.term,
          page: params.page
        };
      },
      processResults: function (data, params) {
        params.page = params.page || 1;

        return {
          results: data.items,
          pagination: {
            more: (params.page * 30) < data.total_count
          }
        };
      },
      cache: true
    },
    escapeMarkup: function (markup) { return markup; },
    minimumInputLength: 1,
    templateResult: function (order) {
      if (!order.id) {
        return 'Aguarde...'
      }

      return `Nº OS: ${order.id} - ${order.user.name} - ${order.customer_name}`
    },
    templateSelection: formatOrders
  });

  bindingValidateForm();
  bindingClearForm();

  $('.admin-club-discount-check-box').on('click', function () {
    $('.admin-select-architect').toggleClass('d-none');
  });

  $('.calculate-amount').on('change', function () {
    var total = 0.0;

    $('.calculate-amount').each(function () {
      var value = parseFloat($(this).val().replace('.', '').replace(',', '.'));

      if (!isNaN(value)) {
        total += value;
      }
    });

    total = parseFloat(parseFloat(total).toFixed(2)).toLocaleString('pt-BR', {
      currency: 'BRL',
      minimumFractionDigits: 2
    });

    $('#amount-order').attr('value', total);
    $('#total-charged-amount').attr('value', total);
  });
});
