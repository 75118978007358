document.addEventListener("turbolinks:load", function() {
  $('#partner_query_filter').on('change', function () {
    let value = $("#partner_query_filter").val();
    Turbolinks.visit('/admin/users?query=' + value)
  })

  $('.suppliers-select-remote').select2({
    ajax: {
      url: $('.suppliers-select-remote').data('load-url'),
      dataType: 'json',
      delay: 500,
      data: function (params) {
        return {
          query: params.term,
          page: params.page
        };
      },
      processResults: function (data, params) {
        params.page = params.page || 1;

        return {
            results: data.items,
            pagination: {
                more: (params.page * 30) < data.total_count
            }
        };
      },
      cache: true
    },
    language: 'pt-BR',
    minimumInputLength: 1,
    templateResult: function(item) {
      if (!item.id) {
        return 'Aguarde...'
      }

      return item.name
    },
    templateSelection: function(item) {
      return item.name || item.text
    }
  });
});
