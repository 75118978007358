import $ from 'jquery';

function scrollEndChat() {
  $('.chat-button_modal_message_content').scrollTop($('.chat-message-container').height() + 100);
}

function toggleBoxMessage() {
  $('.chat-button_modal_message').toggle(300, function () {
    scrollEndChat();
  });
}

function toggleLabel() {
  const currentText = $('.chat-button_actions button').html().trim();
  const toggleText = $('.chat-button_actions button').data('toggle-content').trim();

  $('.chat-button_actions button').html(toggleText);
  $('.chat-button_actions button').data('toggle-content', currentText);
}

function registerMessage() {
  const inputMessage = $('.chat-button_modal_message_footer .chat-button_modal_input_message');
  const textMessage = inputMessage.val();
  const orderId = $('.chat-button_modal_message_content').data('chatOrder');
  const partnerId = $('.chat-button_modal_message_content').data('chatPartner');
  const adminChatUrl = $('.chat-button_modal_send_message').data('chatUrl')

  if (textMessage.length < 1) {
    alert('Favor preencher campo de mensagem');
  } else {
    $.ajax({
      method: "POST",
      url: adminChatUrl,
      data: { message: textMessage, order_id: orderId, auction_partner_id: partnerId }
    }).done(function (_data) {
      const contentMessageEl = $('.chat-button_modal_message_content .chat-message-container');
      contentMessageEl.append(`<p class="chat-message-wrapper chat-my"><span class="chat-message">${textMessage}</span></p>`)
      scrollEndChat();
      inputMessage.val('')
    }).fail(function () {
      alert('Erro ao enviar mensagem');
    });
  }
}

function toggleChat() {
  toggleLabel();
  toggleBoxMessage();
}

function recordAudio() {
  $(".uppy-DashboardTab-name:contains('Audio')").trigger('click');
  $('#chat_order_uploader').toggleClass('d-none');
}

function uploadAudio(field, file) {
  const contentMessageEl = $('.chat-button_modal_message_content .chat-message-container');

  $('#chat_order_uploader').toggleClass('d-none');

  setTimeout(function () {
    $(field).find('.uppy-DashboardContent-back').trigger('click');
  }, 100);

  var formData = new FormData();

  formData.append('order_id', $(field).data('order-id'))
  formData.append('attachment', file.data, "audio.wav")

  contentMessageEl.append(`<p class="chat-message-wrapper chat-my">
    <span class="spinner-border bg-white" role="status"></span>
  </p>`);
  scrollEndChat();

  $.ajax({
    method: "POST",
    url: $(field).data('chat-url'),
    data: formData,
    processData: false,
    contentType: false
  }).done(function (data) {
    contentMessageEl.children(':last-child').html(`
      <span class="chat-message">
        <audio controls>
          <source src="${data.chat_message.attachment.url}" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
      </span>
    `);
    scrollEndChat();
  }).fail(function () {
    alert('Erro ao enviar mensagem');
  });
}

function reloadPageWithParams() {
  let url = window.location.href;

  url = url.replace(/[?&]*openchat=true/, '')

  if (url.indexOf('?') > -1) {
    url += '&openchat=true'
  } else {
    url += '?openchat=true'
  }

  window.location.href = url;
}

function openChatByParams() {
  const url = window.location.href;

  if (url.indexOf('openchat') > -1) {
    toggleChat();
  }
}

function bindings() {
  $(function () {
    $('.chat-button_actions').on('click', function () {
      toggleChat();
    })

    $('.chat-button_modal_message_footer .chat-button_modal_send_message').on('click', function () {
      registerMessage();
    })

    $('.chat-button_modal_message_footer .chat-button_modal_input_message').on('keyup', function (event) {
      if (event.key === "Enter") {
        registerMessage();
      }
    })

    $('.chat-button_modal_message_footer .chat-button_modal_record_audio').on('click', function () {
      recordAudio();
    })

    $('.chat-button_reload_chat').on('click', function () {
      reloadPageWithParams();
    })
  });
}

class ChatButton {
  init() {
    bindings();
    openChatByParams();
  }

  uploadAudio(field, file) {
    uploadAudio(field, file);
  }
}

export { ChatButton };
