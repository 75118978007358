require("@rails/ujs").start()
require("turbolinks").start()

// Limitless
import 'limitless/bootstrap.bundle.min'
import 'limitless/uniform.min'

// Lib JS
import 'jquery-validation'
import 'jquery-validation/dist/localization/messages_pt_BR.js'
import 'lightbox2/dist/css/lightbox'
import lightbox from 'lightbox2/dist/js/lightbox'

document.addEventListener("turbolinks:load", function () {
  lightbox.option({
    'albumLabel': 'Imagem %1 de %2',
  });
  lightbox.init();
});

// CSS
import 'css/admin'
import 'shared/init'
import 'admin/init'

// Custom JS
import 'admin/client'
import 'admin/order'
import 'admin/user'
import 'admin/construction'

// Chat
import 'chat'
