document.addEventListener("turbolinks:load", function () {
  const collapseLink = $(".collapse-link");

  collapseLink.on("click", function () {
    const cardToCollapse = $(this)
      .closest(".card")
      .find(`.${$(this).data("collapse-card")}`);
    const iconCollapse = $(this).find(".collapse-icon-link");

    cardToCollapse.slideToggle(400);
    iconCollapse.toggleClass("rotate-180");
  });
});
