import "jquery-mask-plugin";
import "pickadate/lib/picker";
import "pickadate/lib/picker.date";
import "pickadate/lib/picker.time";
import "pickadate/lib/translations/pt_BR";
import "./order-uploaders";
import "./form-uploader";
import "./collapse";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import calendarLocale from "@fullcalendar/core/locales/pt-br";

import flatpickr from "flatpickr";
import minMaxTimePlugin from "flatpickr/dist/plugins/minMaxTimePlugin";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import "flatpickr/dist/flatpickr.min.css";
import "select2";
import "select2/dist/js/i18n/pt-BR";

document.addEventListener("turbolinks:before-cache", function () {
  $(".form-control-select2").select2("destroy");
  $.uniform.restore($(".form-control-uniform"));
  $(".datatable-scroll-y").DataTable().destroy();
  $(".datatable-scroll-without-default-order").DataTable().destroy();
});

document.addEventListener("turbolinks:load", function () {
  // Select2 init
  function initSelect2() {
    $(".form-control-select2").select2({ tags: true });

    $(".form-control-select2.form-control-select2-tags").select2({
      tags: true,
      dropdownParent: $(".select2-tags-container"),
    });
  }

  initSelect2();
  window.initSelect2 = initSelect2;

  // Toggle min sidebar class
  $(".sidebar-main-toggle").on("click", function (e) {
    e.preventDefault();

    $("body").toggleClass("sidebar-xs").removeClass("sidebar-mobile-main");
    revertBottomMenus();
  });

  // Toggle min sidebar mobile class
  $(".sidebar-mobile-main-toggle").on("click", function (e) {
    e.preventDefault();

    $("body")
      .toggleClass("sidebar-mobile-main")
      .removeClass("sidebar-mobile-secondary sidebar-mobile-right");

    if ($(".sidebar-main").hasClass("sidebar-fullscreen")) {
      $(".sidebar-main").removeClass("sidebar-fullscreen");
    }
  });

  // Expand sidebar to full screen on mobile
  $(".sidebar-mobile-expand").on("click", function (e) {
    e.preventDefault();
    var $sidebar = $(this).parents(".sidebar"),
      sidebarFullscreenClass = "sidebar-fullscreen";

    if (!$sidebar.hasClass(sidebarFullscreenClass)) {
      $sidebar.addClass(sidebarFullscreenClass);
    } else {
      $sidebar.removeClass(sidebarFullscreenClass);
    }
  });

  // Collapse card on click
  $(".card [data-action=collapse]:not(.disabled)").on("click", function (e) {
    var $target = $(this),
      slidingSpeed = 150;

    e.preventDefault();
    $target.parents(".card").toggleClass("card-collapsed");
    $target.toggleClass("rotate-180");
    $target
      .closest(".card")
      .children(".card-header")
      .nextAll()
      .slideToggle(slidingSpeed);
  });

  // Define default class names and options
  var navClass = "nav-sidebar",
    navItemClass = "nav-item",
    navItemOpenClass = "nav-item-open",
    navLinkClass = "nav-link",
    navSubmenuClass = "nav-group-sub",
    navSlidingSpeed = 250;

  // Configure collapsible functionality
  $("." + navClass).each(function () {
    $(this)
      .find("." + navItemClass)
      .has("." + navSubmenuClass)
      .children("." + navItemClass + " > " + "." + navLinkClass)
      .not(".disabled")
      .on("click", function (e) {
        e.preventDefault();

        // Simplify stuff
        var $target = $(this),
          $navSidebarMini = $(".sidebar-xs")
            .not(".sidebar-mobile-main")
            .find(".sidebar-main ." + navClass)
            .children("." + navItemClass);

        // Collapsible
        if ($target.parent("." + navItemClass).hasClass(navItemOpenClass)) {
          $target
            .parent("." + navItemClass)
            .not($navSidebarMini)
            .removeClass(navItemOpenClass)
            .children("." + navSubmenuClass)
            .slideUp(navSlidingSpeed);
        } else {
          $target
            .parent("." + navItemClass)
            .not($navSidebarMini)
            .addClass(navItemOpenClass)
            .children("." + navSubmenuClass)
            .slideDown(navSlidingSpeed);
        }

        // Accordion
        if ($target.parents("." + navClass).data("nav-type") == "accordion") {
          $target
            .parent("." + navItemClass)
            .not($navSidebarMini)
            .siblings(":has(." + navSubmenuClass + ")")
            .removeClass(navItemOpenClass)
            .children("." + navSubmenuClass)
            .slideUp(navSlidingSpeed);
        }
      });
  });

  // Disable click in disabled navigation items
  $(document).on("click", "." + navClass + " .disabled", function (e) {
    e.preventDefault();
  });

  // Mask tel
  $("input.phone").mask("(99) 99999-9999");

  // Date picker
  const fp = flatpickr(".date-picker", {
    locale: Portuguese,
    dateFormat: "d/m/Y",
    altInput: true,
    altFormat: "d/m/Y",
  });

  if (fp && !!fp["forEach"]) {
    fp.forEach((calendar) => {
      if (calendar.element.dataset.disableMinDate) {
        calendar.set("minDate", null);
      }
    });
  }

  flatpickr.l10ns.default.firstDayOfWeek = 1;

  // Date picker
  let calendarFlat = flatpickr(".datetime-picker", {
    locale: Portuguese,
    enableTime: true,
    dateFormat: "d/m/Y H:i",
    altInput: true,
    altFormat: "d/m/Y - H:i",
    time_24hr: true,
    plugins: [
      mobileFix(),
      new minMaxTimePlugin({
        table: getLockDate().partial_dates,
      }),
    ],
    disable: getLockDate().full_dates,
  });

  flatpickr(".time-picker", {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    time_24hr: true,
  });

  $("#order_user_id").on("change", function () {
    if ($(this).val()) {
      $.ajax({
        type: "GET",
        url: `/admin/schedule/lock/${$(this).val()}/dates.json`,
        async: false,
        dataType: "json",
      }).done(function (response) {
        calendarFlat.forEach((calendar) => {
          calendar.destroy();
        });

        calendarFlat = flatpickr(".datetime-picker", {
          locale: Portuguese,
          enableTime: true,
          dateFormat: "d/m/Y H:i",
          altInput: true,
          altFormat: "d/m/Y - H:i",
          time_24hr: true,
          plugins: [
            mobileFix(),
            new minMaxTimePlugin({
              table: response.partial_dates,
            }),
          ],
          disable: response.full_dates,
        });
      });
    }
  });

  $("#construction_steps_user_id").on("change", function () {
    if ($(this).val()) {
      $.ajax({
        type: "GET",
        url: `/admin/schedule/lock/${$(this).val()}/dates.json`,
        async: false,
        dataType: "json",
      }).done(function (response) {
        calendarFlat.forEach((calendar) => {
          calendar.destroy();
        });

        calendarFlat = flatpickr(".date-picker", {
          locale: Portuguese,
          enableTime: false,
          dateFormat: "d/m/Y",
          altInput: true,
          altFormat: "d/m/Y",
          time_24hr: true,
          plugins: [
            mobileFix(),
            new minMaxTimePlugin({
              table: response.partial_dates,
            }),
          ],
          disable: response.full_dates,
        });
      });
    }
  });

  $("#get-user-lock-schedule").on("click", function () {
    if ($(this).data("user-id")) {
      $.ajax({
        type: "GET",
        url: `/schedule/lock/${$(this).data("user-id")}/dates.json`,
        async: false,
        dataType: "json",
      }).done(function (response) {
        calendarFlat.forEach((calendar) => {
          calendar.destroy();
        });

        calendarFlat = flatpickr(".date-picker", {
          locale: Portuguese,
          enableTime: false,
          dateFormat: "d/m/Y",
          altInput: true,
          altFormat: "d/m/Y",
          time_24hr: true,
          plugins: [
            mobileFix(),
            new minMaxTimePlugin({
              table: response.partial_dates,
            }),
          ],
          disable: response.full_dates,
        });
      });
    }
  });

  // Date range picker
  flatpickr(".daterange-picker", {
    locale: Portuguese,
    dateFormat: "d/m/Y",
    altInput: true,
    altFormat: "d/m/Y",
    mode: "range",
    onClose: function (_selectedDates, dateStr, instance) {
      if (instance.input.id == "create-invoice-date-range") {
        loadInvoiceOrders();
      }
    },
  });

  $(".monetize").mask("#.##0,00", { reverse: true });

  if ($("#fullcalendar-agenda").length > 0) {
    let calendarEl = document.getElementById("fullcalendar-agenda");

    let calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      headerToolbar: {
        start: "prev,next",
        center: "title",
        end: calendarEl.dataset.toolbarViews,
      },
      titleFormat: {
        month: "short",
        day: "numeric",
      },
      initialDate: Date.now(),
      initialView: calendarEl.dataset.initialView,
      editable: true,
      events: {
        url: calendarEl.dataset.eventsUrl,
        extraParams: function () {
          return {
            user_id: $("#schedule-filter-user-id").val(),
            status: $("#schedule-filter-status").val(),
            partner_id: $("#filter-calendar-partner-id").val(),
            start_date: $("#filter-calendar-date-start").val(),
            end_date: $("#filter-calendar-date-end").val(),
          };
        },
      },
      firstDay: 1,
      hiddenDays: [],
      slotMinTime: "07:00:00",
      slotMaxTime: "22:00:00",
      locale: calendarLocale,
      allDaySlot: false,
      expandRows: true,
      eventDisplay: "block",
      eventContent: function (data) {
        if (data.event.extendedProps.default_template) {
          let description = data.event.extendedProps.description
            ? ` - ${data.event.extendedProps.description}`
            : "";
          let user_name = data.event.extendedProps.user_name
            ? `<b>Prestador:</b> <br />${data.event.extendedProps.user_name} <br /><br />`
            : "";

          let div = document.createElement("div");
          div.innerHTML = `${user_name}${data.event.title}${description}`;

          return { domNodes: [div] };
        } else {
          let badge = document.createElement("div");

          if (
            data.event.with_actions ||
            data.event.extendedProps.with_actions
          ) {
            badge.innerHTML = `<div class="d-flex justify-content-between">
                                <div>
                                  <span class="badge bg-white text-dark">
                                    ${data.event.title}
                                  </span>
                                  <a href="${data.event.url}" class="text-white">
                                    <i class="icon-pencil5 icon-large p-1"></i>
                                  </a>
                                </div>
                                <a data-confirm="Tem certeza?" rel="nofollow" data-method="delete" href="${data.event.extendedProps.schedule_delete_url}" class="text-white">
                                  <i class="icon-trash-alt icon-large p-1"></i>
                                </a>
                              </div>`;
          }

          let description = document.createElement("div");
          description.innerHTML = data.event.extendedProps.description;

          let arrayOfDomNodes = [badge, description];

          return { domNodes: arrayOfDomNodes };
        }
      },
      eventClassNames: ["overflow-hidden"],
      views: {
        timeGrid3Day: {
          type: "timeGrid",
          duration: { days: 3 },
          buttonText: "3 dias",
        },
      },
      height: "auto",
      eventStartEditable: false,
      lazyFetching: false,
    });

    calendar.render();

    $(
      "#schedule-filter-user-id, #schedule-filter-status, #filter-calendar-date-end, #filter-calendar-date-start, #filter-calendar-partner-id"
    ).on("change", () => {
      calendar.refetchEvents();
    });
  }

  $("#create-invoice-user-id").on("change", () => {
    loadInvoiceOrders();
  });

  bindingClearFormModal();

  $(".notification-link").on("click", function (e) {
    e.preventDefault();

    let link = $(this);

    $.post(
      `/notifications/${link.data("notification-id")}/mark_as_read`
    ).always(() => {
      Turbolinks.visit(link.attr("href"));
    });
  });
});

function mobileFix() {
  return function (instance) {
    return {
      onParseConfig: function () {
        if (instance.isMobile) {
          return;
        }

        if (
          window.navigator.userAgent.match(/iPad/i) ||
          window.navigator.userAgent.match(/iPhone/i) ||
          /iPad|iPhone|iPod/.test(window.navigator.platform) ||
          (window.navigator.platform === "MacIntel" &&
            window.navigator.maxTouchPoints > 1)
        ) {
          instance.isMobile = true;
        }
      },
    };
  };
}

function getLockDate() {
  let lockDate = $(".datetime-picker").data("is-lock");

  return {
    partial_dates: lockDate ? lockDate.partial_dates : {},
    full_dates: lockDate ? lockDate.full_dates : [],
  };
}

function loadInvoiceOrders() {
  window.location.search =
    "?user_id=" +
    $("#create-invoice-user-id").val() +
    "&date_range=" +
    $("#create-invoice-date-range").val();
}

function bindingClearFormModal() {
  $(".clearModal").on("click", function () {
    $(".modal form").each(function () {
      clearForm($(this));
    });
  });
}

function clearForm(form) {
  form.find("input, textarea").each(function () {
    let field = $(this);

    if (field.attr("type") == "hidden") {
      return;
    } else if (field.attr("type") == "checkbox") {
      field.prop("checked", false);
    } else {
      field.val("");
    }
  });

  if (form.find(".form-control-select2").length > 0) {
    form.find(".form-control-select2").select2("val", 0);
  }

  if (form.find(".form-uploader-container").length > 0) {
    $(".uppy-Dashboard-Item-action--remove").each(function () {
      this.click();
    });
  }

  $(".date-picker").val("");
}
