const Uppy = require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
const AwsS3 = require('@uppy/aws-s3')
const Form = require('@uppy/form')
const ptBR = require('@uppy/locales/lib/pt_BR')
const Audio = require('@zadkiel/uppy-audio')

require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')
require('@zadkiel/uppy-audio/src/style.scss')

import { ChatButton } from '../chat/components/chat_button';

var order_uploaders = order_uploaders || {};

order_uploaders = (function ($) {
  'use strict';

  let uploaders = []
  let uploaders_finished = []

  function init() {
    if ($('#uploaders').length > 0) {
      uploaders = $('#uploaders').val().split(",")

      uploaders.forEach(field => {
        uploader(field)
      })
    }

    $("#form-order button[type=submit]").on('click', (e) => {
      $('#order-status-button-sent').val($(e.target).data('button-clicked'))
    })

    if ($('#chat_order_uploader').length > 0) {
      uploader('chat_order')
    }
  }

  function uploader(field) {
    const uploader_field = `#${field}_uploader`

    if ($(uploader_field).length > 0) {
      const audioEnabled = $(uploader_field).data('enable-audio');

      const uppy = new Uppy({
        id: `uppy_${field}`,
        debug: true,
        locale: ptBR
      })

      uppy.use(Dashboard, {
        inline: true,
        hideUploadButton: true,
        target: uploader_field,
        height: audioEnabled ? 300 : 120
      })

      uppy.use(AwsS3, {
        getUploadParameters(file) {
          const csrfToken = document.head.querySelector("[name='csrf-token']")

          return fetch('/aws/sign-url', {
            method: 'post',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'X-CSRF-Token': csrfToken.content
            },
            body: JSON.stringify({
              file: file,
              order_id: $('#form-order').data('model-id'),
              auction_partner_id: $('#form-order').data('auction-partner-id'),
              field: field
            })
          }).then((response) => {
            return response.json()
          }).then((data) => {
            return {
              method: data.method,
              url: data.url,
              fields: data.fields,
              headers: {
                'content-type': data.headers.content_type,
                'x-amz-acl': data.headers.acl
              }
            }
          })
        }
      })

      if (field != 'chat_order') {
        uppy.use(Form, {
          target: '#form-order',
          resultName: `uppy_${field}[]`,
          getMetaFromForm: false,
          triggerUploadOnSubmit: true
        })
      }

      if (audioEnabled) {
        uppy.use(Audio, {
          target: Dashboard,
          encoderPath: '/waveWorker.min.js',
        })
      }

      if (field == 'chat_order') {
        uppy.on('file-added', (file) => {
          const chatButton = new ChatButton();

          chatButton.uploadAudio(uploader_field, file);
        })
      } else {
        uppy.on('upload', () => {
          $('#form-order').find('button[type=submit]').attr('disabled', true)

          let index = uploaders_finished.indexOf(field)

          if (index >= 0) {
            uploaders_finished.splice(index, 1)
          }
        })

        uppy.on('complete', () => {
          uploaders_finished.push(field)

          if (uploaders_finished.length == uploaders.length) {
            $('#form-order').trigger('submit')
          }
        })
      }
    }
  }

  return {
    init: init
  };
})(jQuery);

document.addEventListener("turbolinks:load", function () {
  order_uploaders.init()
})
